<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import axios from "axios";
/**
 * Form validation component
 */
export default {
    page: {
        title: "Publicités",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, ckeditor: CKEditor.component },
    data() {
        return {
            title: "Publicités",
            tmpCoverFile: null,
            file: null,
            fileModif: null,
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Ajouter",
                    active: true,
                },
            ],
            adsForm: {
                title: "",
                text: "",
                link: "",
                phone: "",
                phoneCode: "",
                rayon: "",
                endAt: "",
                startAt: "",
                img: "",
                lieu: "",
                lieuLng: "",
                lieuLat: "",
            },
            adsFormModif: {
                id: "",
                title: "",
                text: "",
                link: "",
                phone: "",
                phoneCode: "",
                rayon: "",
                endAt: "",
                startAt: "",
                img: "",
                lieu: "",
                lieuLng: "",
                lieuLat: "",
            },

            
            totalRows: 1,
            currentPage: 1,
            perPage: 100,
            pageOptions: [100, 250, 500],
            filter: null,
            adsList: [],
            activeAds: [],
            disabledAds: [],
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            fields: [
                { key: "title", sortable: true, label: "title" },
                { key: "createdAt", sortable: true },
                { key: "startAt", sortable: true, label: "Date de début" },
                { key: "endAt", sortable: true, label: "Date de Fin" },
                { key: "lieu", sortable: true, label: "lieu" },
                { key: "rayon", sortable: true, label: "Rayon" },
                { key: "adminId", sortable: true, label: "Publié par" },

                { key: "action" },
            ],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            showDelete: false,
            showEdit: false,
            showStat: false,
            showdisable: false,
            showactive: false,
            editor: ClassicEditor,
            statData:[]
           

        };
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            const allAds = await apiRequest("GET", "pubs-list ", undefined, false);
            console.log("all ads", allAds)
            if (allAds && allAds.data) {
                const adsMap = allAds.data.map((ads) => {
                    return {
                        title: ads.title,
                        id: ads.id,
                        link: ads.link,
                        active: ads.active,
                        text: ads.text,
                        visible: ads.visible,
                        lieu: ads.lieu,
                        lieuLng: ads.lieuLng,
                        lieuLat: ads.lieuLat,
                        phone: ads.phone,
                        phoneCode: ads.phoneCode,
                        rayon: ads.rayon,
                        createdAt: new Date(ads.createdAt).toLocaleDateString("fr-FR"),
                        startAt: new Date(ads.startAt).toLocaleDateString("fr-FR"),
                        endAt: new Date(ads.endAt).toLocaleDateString("fr-FR"),
                        // adminId:
                        //     ads.admin != null
                        //         ? ads.admin.firstname + " " + ads.admin.lastnameate
                        //         : "",
                    };
                });
                this.adsList = adsMap;
                this.disabledAds = [];
                this.activeAds = [];


            }
        },

        async getStat(id){
            
            const statId = parseInt(id)

            // url
            const url = `pub-one?id=${statId}`

            // Make API request
            const response = await apiRequest('GET', url)

            // Check
        if (response && response.data) {

        console.log(response.data)

        const formattedData = response.data

        this.statData = formattedData;
        console.log(this.statData)
        this.showStat = true
        

      }

        },


        async disableAds() {

            const req = await apiRequest('POST', 'pubs-action', { id: this.adsFormModif.id, action: "DISABLE" });
            if (req.status == 200) {
                this.showdisable = false
                this.$toast.success("Publicité désactivée avec succès");
                this.init();
            } else {

                this.$toast.error("Un problème est survenu");

            }

        },
        async enableAds() {

            const req = await apiRequest('POST', 'pubs-action', { id: this.adsFormModif.id, action: "ENABLE" });
            if (req.status == 200) {
                this.showactive = false;
                this.$toast.success("Publicité activée avec succès");
                this.init();
            }

        },
        async deleteAds() {

            const ads = {
                id: this.adsFormModif.id,
                action: "DESTROY",
            }

            const request = await apiRequest(
                'POST',
                `pubs-action`,
                ads,
            )

            if (request) {
                this.showDelete = false
                if (request.status == 200) {
                    this.$toast.success('Publicité supprimée avec succès', 'Fait!')
                    this.showDelete = false
                    this.init()
                } else if (request.status == 500) {
                    this.$toast.warn(
                        "le serveur a rencontré une erreur interne. Veuillez réessayer plus tard",
                        'Erreur serveur',
                    )
                    this.showDelete = false
                }
            }

        },
        populateModif(row) {
            this.adsFormModif.id = row.id;
            this.adsFormModif.title = row.title;
            this.adsFormModif.img = row.img;
            this.adsFormModif.link = row.link;
            this.adsFormModif.text = row.text;
            this.adsFormModif.endAt = row.endAt;
            this.adsFormModif.startAt = row.startAt;
            this.adsFormModif.rayon = row.rayon;
            this.adsFormModif.lieu = row.lieu;
            this.adsFormModif.lieuLat = row.lieuLat;
            this.adsFormModif.lieuLng = row.lieuLng;
            this.adsFormModif.phone = row.phone;
            this.adsFormModif.phoneCode = row.phoneCode;
        },
        editRow(row) {
            this.showEdit = true;
            this.populateModif(row);


            console.log('pub to iupdate', this.adsFormModif)
        },
        desactiverRow(row) {
            this.showdisable = true;
            this.populateModif(row);
        },
        activerRow(row) {
            this.showactive = true;
            this.populateModif(row);
        },
        deleteRow(row) {
            this.showDelete = true;
            this.populateModif(row);
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            this.$v.$touch();
        },

        tooltipForm() {
            this.submitform = true;
            this.formSubmit();
            this.$v.$touch();
        },

        handleFileUpload() {
            this.file = this.$refs.file.files[0];

        },
        handleFileUploadModif() {
            this.fileModif = this.$refs.files.files[0];
        },

        handleFileUploads() {
            this.audioLink = ""
            this.fileaudio = this.$refs.audioInput.files[0];
            const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
            const fileType = this.fileaudio.type;

            if (!allowedTypes.includes(fileType)) {
                // Display an error message or perform some action when an invalid file type is selected.
                console.error("Invalid file type");
                return;
            }

            // File is valid, proceed with further actions.

        },


        countryChanged(country) {
            this.adsForm.phoneCode = "+" + country.dialCode;
            console.log("code ", this.adsForm.phoneCode,)
            //customerData.value.phoneCode = countryCode.value;
            //console.log("indicatifTel indicatifTel", indicatifTel.value);
        },
        getPhone() {

            this.adsForm.phone = this.adsForm.phone.replace(/\s/g, '');
            console.log(this.adsForm.phoneCode + this.adsForm.phone)

        },
        async createPub() {

            /*   formData.append("audioLink", ""); */
            if (this.fileaudio != null) {

                this.uploadImage(this.fileaudio)
            }
            else {
                this.adsForm.img = this.file;
                let formData = new FormData();
                formData.append("title", this.adsForm.title);
                formData.append("text", this.adsForm.text);
                formData.append("link", this.adsForm.link);
                formData.append("startAt", this.adsForm.startAt);
                formData.append("endAt", this.adsForm.endAt);
                formData.append("phoneCode", this.adsForm.phoneCode);
                formData.append("phone", this.adsForm.phone);
                formData.append("rayon", this.adsForm.rayon);
                formData.append("lieu", this.adsForm.lieu);
                formData.append("lieuLng", this.adsForm.lieuLng);
                formData.append("lieuLat", this.adsForm.lieuLat);
                formData.append("img", this.adsForm.img);
                const request = await apiRequest("POST", "add-pub", formData, true);

                if (request && request.status == 200) {


                    this.$toast.success("Publicitée crée avec succès !!!", {
                        position: "bottom-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                    this.init();
                }

                else {

                    this.$toast.error("Publicité non créé", {
                        position: "bottom-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                }
            }








        },
        async updatePub() {
            this.adsFormModif.img = this.fileModif
            let formData = new FormData();
            formData.append("id", this.adsFormModif.id);
            formData.append("title", this.adsFormModif.title);
            formData.append("text", this.adsFormModif.text);
            formData.append("link", this.adsFormModif.link);
            formData.append("startAt", this.adsFormModif.startAt);
            formData.append("endAt", this.adsFormModif.endAt);
            formData.append("phoneCode", this.adsFormModif.phoneCode);
            formData.append("phone", this.adsFormModif.phone);
            formData.append("rayon", this.adsFormModif.rayon);
            formData.append("lieu", this.adsFormModif.lieu);
            formData.append("lieuLng", this.adsFormModif.lieuLng);
            formData.append("lieuLat", this.adsFormModif.lieuLat);
            formData.append("img", this.adsFormModif.img);

            const request = await apiRequest("PUT", "pubs-update", formData, true);

            if (request && request.status == 200) {

                // await apiRequest("PUT", "pubs-update-image",this.adsFormModif.img)
                this.showEdit = false;
                this.$toast.success("Opération rélaisé avec succès")
                this.init();
            } else {
                this.showEdit = false;
                this.$toast.error('Une erreur est survenue !')
            }
        },


        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        /**
         * Range validation form submit
         */
        // eslint-disable-next-line no-unused-vars
        rangeform(e) {
            this.submit = true;
            this.$v.$touch();
        },
        /**
         * Validation type submit
         */
        // eslint-disable-next-line no-unused-vars
        typeForm(e) {
            this.typesubmit = true;
            this.$v.$touch();
        },
        setAdress: function (place) {

            if (place) {
                this.adsForm.lieu = place.formatted_address;
                this.adsForm.lieuLng = place.geometry.location.lng();
                this.adsForm.lieuLat = place.geometry.location.lat();
            }
        },

        changeAdress: function (place) {

            if (place) {
                this.adsFormModif.lieu = place.formatted_address;
                this.adsFormModif.lieuLng = place.geometry.location.lng();
                this.adsFormModif.lieuLat = place.geometry.location.lat();
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <form enctype="multipart/form-data" @submit.prevent="createPub">
                            <div class="form-group">
                                <label for="tite">Titre</label>
                                <input type="text" id="title" class="form-control" placeholder="titre"
                                    v-model="adsForm.title" />
                            </div>
                            <div class="form-group">
                                <label for="tite">Contenu</label>

                                <ckeditor v-model="adsForm.text" :editor="editor" aria-placeholder="Sous-title">
                                </ckeditor>
                            </div>
                            <div class="row">

                                <div class=" col-md-6 form-group">
                                    <label for="tite">Lien du bon ads</label>
                                    <input type="text" id="title" class="form-control" placeholder="Lien du bon ads"
                                        v-model="adsForm.link" />
                                </div>

                                <div class=" col-md-6 form-group ">

                                    <template>
                                        <label for="phone">Téléphone</label>
                                        <vue-tel-input id="phone" v-model="adsForm.phone" class=""
                                            :inputOptions="{ placeholder: 'Numéro de téléphone' }"
                                            @country-changed="countryChanged" @input="getPhone"></vue-tel-input>
                                    </template>


                                </div>

                            </div>

                            <div class="row form-group">
                                <div class="col-md-6 form-group">
                                    <label for="startAt">Lieu</label>
                                    <gmap-autocomplete :value="adsForm.lieu" type="text" class="form-control"
                                        id="depart" placeholder="Lieu" @place_changed="setAdress"
                                        :select-first-on-enter="true">
                                    </gmap-autocomplete>

                                </div>
                                <div class=" col-md-6 form-group">
                                    <label for="rayon">Rayon</label>
                                    <input type="text" id="rayon" class="form-control" placeholder="Rayon de diffusion"
                                        v-model="adsForm.rayon" />
                                </div>

                            </div>
                            <div class="row">

                                <div class=" col-md-6 form-group">
                                    <label for="startAt">Début de la publicité</label>
                                    <input type="date" id="startAt" class="form-control" v-model="adsForm.startAt" />
                                </div>

                                <div class="col-md-6 form-group">
                                    <label for="endAT">Fin de la publicité</label>
                                    <input type="date" id="endAT" class="form-control" v-model="adsForm.endAt" />
                                </div>

                            </div>



                            <fieldset>
                                <div class="form-group">
                                    <label for="tite">Image de la publicité</label>
                                    <input type="file" id="tite" ref="file" v-on:change="handleFileUpload"
                                        class="form-control" />
                                </div>

                            </fieldset>

                            <div class="form-group">
                                <button class="btn btn-primary float-right">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            <h5>Gérer</h5>
                        </div>
                        <form>
                            <b-tabs nav-class="nav-tabs-custom">
                                <b-tab title-link-class="p-3">
                                    <template v-slot:title>
                                        <a class="font-weight-bold active">Liste des publicités</a>
                                    </template>
                                    <div class="row mt-4">
                                        <div class="col-sm-12 col-md-6">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>
                                        <!-- Search -->
                                        <div class="col-sm-12 col-md-6">
                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                <label class="d-inline-flex align-items-center">
                                                    Rechercher:
                                                    <b-form-input v-model="filter" type="search"
                                                        class="form-control form-control-sm ml-2"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="adsList" :fields="fields"
                                            responsive="sm" :per-page="perPage" :current-page="currentPage"
                                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                            :filter-included-fields="filterOn" @filtered="onFiltered">
                                            <template v-slot:cell(action)="row">
                                                <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                                                    @click="editRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-pencil font-size-18"></i>
                                                </a>

                                                <a v-if="row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-danger': row.item.active }" v-b-tooltip.hover
                                                    @click="desactiverRow(row.item)" title="Désactiver">
                                                    <i class="mdi mdi-close font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="getStat(row.item.id)" title="Voir">
                                                    <i class="mdi mdi-eye font-size-18"></i>
                                                </a>
                                                <a v-if="!row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="activerRow(row.item)" title="Activer">
                                                    <i class="mdi mdi-check font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);" class="text-danger"
                                                    @click="deleteRow(row.item)" v-b-tooltip.hover title="supprimer">
                                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                                </a>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPage" :total-rows="adsList.length"
                                                        :per-page="perPage" :limit="1" first-text="◀" last-text="▶"
                                                        aria-controls="my-table">
                                                    </b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                </b-tab>
                                
                            </b-tabs>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-backdrop" centered v-model="showDelete"
            title="Voulez-vous vraiment supprimer cette publicité  ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showDelete = false">Non</b-button>
                <b-button variant="success" @click="deleteAds()" class="ml-3 mr-4">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-backdrop" centered v-model="showdisable"
            title="Voulez-vous vraiment désactiver cette publicité  ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showdisable = false">Non</b-button>
                <b-button variant="success" @click="disableAds()" class="ml-3 mr-4">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-backdrop" centered v-model="showactive"
            title="Voulez-vous vraiment activer cette publicité ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showactive = false">Non</b-button>
                <b-button variant="success" @click="enableAds()" class="ml-3 mr-4">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-center" centered size="xl" v-model="showEdit" title="Editer utilisateur"
            title-class="font-18" hide-footer>
            <form enctype="multipart/form-data" @submit.prevent="updatePub">
                <div class="form-group">
                    <label for="tite">Titre</label>
                    <input type="text" id="title" class="form-control" placeholder="titre"
                        v-model="adsFormModif.title" />
                </div>
                <div class="form-group">
                    <label for="tite">Contenu</label>

                    <ckeditor v-model="adsFormModif.text" :editor="editor" aria-placeholder="Sous-title">
                    </ckeditor>
                </div>
                <div class="row">

                    <div class=" col-md-6 form-group">
                        <label for="tite">Lien du bon ads</label>
                        <input type="text" id="title" class="form-control" placeholder="Lien du bon ads"
                            v-model="adsFormModif.link" />
                    </div>

                    <div class=" col-md-6 form-group ">

                        <template>
                            <label for="phone">Téléphone</label>
                            <vue-tel-input id="phone" v-model="adsFormModif.phone"
                                :inputOptions="{ placeholder: 'Numéro de téléphone' }" @country-changed="countryChanged"
                                @input="getPhone"></vue-tel-input>
                        </template>


                    </div>

                </div>

                <div class="row form-group">
                    <div class="col-md-6 form-group">
                        <label for="lieu">Lieu</label>
                        <gmap-autocomplete :value="adsFormModif.lieu" type="text" class="form-control" id="lieu"
                            placeholder="Lieu" @place_changed="changeAdress" :select-first-on-enter="true">
                        </gmap-autocomplete>

                    </div>
                    <div class=" col-md-6 form-group">
                        <label for="rayon">Rayon</label>
                        <input type="text" id="rayon" class="form-control" placeholder="Rayon de diffusion"
                            v-model="adsFormModif.rayon" />
                    </div>

                </div>
                <div class="row">

                    <div class=" col-md-6 form-group">
                        <label for="startAt">Début de la publicité</label>
                        <input type="date" id="startAt" class="form-control" v-model="adsFormModif.startAt" />
                    </div>

                    <div class="col-md-6 form-group">
                        <label for="endAT">Fin de la publicité</label>
                        <input type="date" id="endAT" class="form-control" v-model="adsFormModif.endAt" />
                    </div>

                </div>



                <fieldset>
                    <div class="form-group">
                        <label for="tite">Image de la publicité</label>
                        <input type="file" id="tite" ref="files" v-on:change="handleFileUploadModif"
                            class="form-control" />
                    </div>

                </fieldset>

                <div class="form-group">
                    <button class="btn btn-primary float-right">Modifier</button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-stat" centered size="lg" v-model="showStat" title="Détails de la publicité"
            title-class="font-18" hide-footer >

            <div class="row p-3 d-flex justify-content-center mx-auto">

                <div class="col-md-4  mx-2 ">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Nombre de vue
                            </div>
                            <div>
                                <div class="mb-3 icon text-danger">
                                    <i class='fas fa-eye'></i>
                                </div>


                                <div class="text-center data">

                                    <span>{{statData.views}}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mx-2 ">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Nombre de clic
                            </div>
                            <div>
                                <div class="mb-3 icon">
                                    <i class='fas fa-mouse-pointer text-warning'></i>
                                </div>


                                <div class="text-center data">

                                    <span>{{statData.clics}}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4  mx-2 ">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Bouton appel
                            </div>
                            <div>
                                <div class="mb-3 icon">
                                    <i class='fas fa-phone text-info'></i>
                                </div>


                                <div class="text-center data">

                                    <span>{{statData.clics_appel}}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4  mx-2">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Nombre de vue
                            </div>
                            <div>
                                <div class="mb-3 icon">
                                    <i class='fas fa-eye'></i>
                                </div>


                                <div class="text-center data">

                                    <span>3</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->


            </div>


        </b-modal>

    </Layout>
</template>

<style>

#modal-stat .modal-body {
    background-color: #dfb0ff42
    ;
}
.stat-detail .title {
    font-size: large;
    font-weight: bolder;
}

.stat-detail .icon {
    font-size: large;
    font-weight: bolder;
}

.stat-detail .data {
    font-size: large;

}

.pac-container {
    z-index: 1051 !important;
}

#showPassword {
    width: 35px;
    height: 35px;
}

thead {
    background: #3aaa35;
}

thead div {
    color: #fff;
}

.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    margin-bottom: 5px;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.inputCol {
    position: relative;
}

.paging_simple_numbers {
    background: #3aaa35;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;

    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
    color: white !important;
}

.paging_simple_numbers ul li button {
    color: #fff !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}


.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: #fff;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35;
    border-bottom: 1px solid #fff;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000;
}
</style>
